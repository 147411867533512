import axios from 'axios';
import { BASE_URL, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleError, handleAllErrorWithCode, getFirstError } from './responseHandler';
import { getConfig, getConfigForDeleteHttpVerb } from './common';




/*start Trial Balance api*/

/*end Trial balance Api*/


/*start schedule 3 api*/

/*end schedule 3 Api*/

/*start form 3ca-3cd api*/

/*end form 3ca-3cd Api*/

/*start itr balance sheet api*/

/*end itr balance sheet Api*/

/*start consolidated sch api*/

/*end consolidated sch Api*/

/*start business info api*/
export async function getBasicInfoData(returnid, ass_id) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/basicinfo?assessee_id=${ass_id}&return_id=${returnid}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
    // return tempReturnData
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function updateBasicInfoApi(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/basicinfo`;

  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

/*end  business info Api*/


/*start Organisation info api*/
export async function getOrgInfoData(returnid, ass_id) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/orgothinfo?assessee_id=${ass_id}&return_id=${returnid}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }
}
export async function updateOrgInfoApi(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/orgothinfo`;
  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }
}
/*end Organisation info api*/



/*start  member list Api*/
export async function getMemberListData(returnid, ass_id) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/memberdet?assessee_id=${ass_id}&return_id=${returnid}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
    // return tempReturnData
  }
  catch (err) {
    throw handleError(err);
  }
}

export async function updateMemberData(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/memberdet`;

  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function deleteMemberListRow(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/memberdet`;

  try {

    const clientData = await axios.delete(url, getConfigForDeleteHttpVerb(data));

    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function addMemberListRow(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/memberdet`;

  try {
    const clientData = await axios.post(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);

  }
  catch (err) {
    throw handleError(err);
  }

}
/*end  member list Api*/



/*start  HOLDING AND SUBSIDIARY status  Api*/

export async function callApi(type = '', geturl = '', data = null) {
  if (geturl === '' || type === '') return;
  let url = `${BASE_URL}${geturl}`;
  let result = {}
  try {
    switch (type.toLocaleLowerCase()) {
      case 'get':
        if (data !== null) {
          url = `${url}?assessee_id=${data?.assessee_id}&return_id=${data?.return_id}`;
        }
        result = await axios.get(url, getConfig());
        break;
      case 'put':
        result = await axios.put(url, data, getConfig());
        break;
      case 'post':
        result = await axios.post(url, data, getConfig());
        break;
      case 'delete':
        result = await axios.delete(url, getConfigForDeleteHttpVerb(data));
        break;
      default:
    }
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function commonApiCall(type = '', geturl = '', data = null) {
  if (geturl === '' || type === '') return;
  let url = `${BASE_URL}${geturl}`;
  let result = {}
  try {
    switch (type.toLocaleLowerCase()) {
      case 'get':
        if (data !== null) {
          url = `${url}?assessee_id=${data?.assessee_id}&return_id=${data?.return_id}`;
        }
        result = await axios.get(url, getConfig());
        break;
      case 'put':
        result = await axios.put(url, data, getConfig());
        break;
      case 'post':
        result = await axios.post(url, data, getConfig());
        break;
      case 'delete':
        result = await axios.delete(url, getConfigForDeleteHttpVerb(data));
        break;
      default:
    }
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  }
  catch (err) {
    throw handleAllErrorWithCode(err);
  }

}
/*end  HOLDING AND SUBSIDIARY status  Api*/

// start gstdetails
export async function getCatchaForGstDetails() {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/gst/captcha`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function captchaImortGSTIN(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/gst/gstin-list?gpc_id=${data.gpc_id}&captcha_text=${data.captcha_text}&pan=${data.pan}`
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }
}

export async function getGstDetailsInfoData(returnid, ass_id) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/gst?assessee_id=${ass_id}&return_id=${returnid}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }
}

export async function updateGstDetailsApi(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/gst`;
  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }
}
// end gst details


//start trial balance api
export async function getConsoDataList(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/trialbal/conso`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

export async function getTrailBalData(data) {
  const url = `${BASE_URL}/api/v1/bp/businessinfo/trialbal`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

//end Trail Balance Api



export async function uploadForm3cdExcel(data) {
  const url = `${BASE_URL}/api/v1/auditforms/3cd/import`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function downloadForm3cdExcelTemplate() {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL}/api/v1/auditforms/3cd/excel-template`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'ImportForm3Cd_Excel_Workbook_Template.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleError(err);
  }
}




export async function uploadSchedule3Excel(data) {
  const url = `${BASE_URL}/api/v1/scheduleiii/import`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function downloadSchedule3dExcelTemplate() {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL}/api/v1/scheduleiii/excel-template`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'ImportSchedule3_Excel_Workbook_Template.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleError(err);
  }
}

// This function is written separately to get previous year computation data
// This ensures that nothing is set in the header selector
export async function getPyComputationData(returnid, assessee_id) {
  const url = `${BASE_URL}/api/v1/computation?assessee_id=${assessee_id}&return_id=${returnid}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return clientData.data;
  }
  catch (err) {
    throw handleError(err);
  }
}

//download 3ca-3cb-3cd json
export async function downloadAuditJson(assesseeId, returnId, ipAddress) {
  const apiUrl = `${BASE_URL}/api/v1/auditforms/download-audit-json?assessee_id=${assesseeId}&return_id=${returnId}&IpAddress=${ipAddress}`;
  try {
      const config = { ...getConfig(), responseType: 'blob' };
      const result = await axios.get(apiUrl, config);
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (result?.headers?.[HEADER_FILE_NAME] ?? 'ITR.json'));
      document.body.appendChild(link);
      link.click();
  } catch (err) {
      let errorString = ""
      if (
          err.request.responseType === 'blob' &&
          err.response.data instanceof Blob &&
          err.response.data.type &&
          err.response.data.type.toLowerCase().indexOf('json') !== -1
      ) {
          errorString = getFirstError(JSON.parse(await err.response.data.text()));
      } else {
          errorString = handleError(err);
      }

      throw errorString;

  }
}

export async function downloadFSPreviewPDF(assesseeId, returnId) {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const apiUrl = `${BASE_URL}/api/v1/financial-statement/preview/pdf?assessee_id=${assesseeId}&return_id=${returnId}`;
    const result = await axios.get(apiUrl, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'FS-Preview-PDF');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleError(err);
  }
};

export async function downloadAuditReportPDF(assesseeId, returnId) {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const apiUrl = `${BASE_URL}/api/v1/auditforms/3cd/download-pdf?assessee_id=${assesseeId}&return_id=${returnId}`;
    const result = await axios.get(apiUrl, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'Audit-Report-PDF');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw getFirstError(JSON.parse(await err.response.data.text()));
  }
};
